/* src/Components/Timer/Timer.css */
.countdown-timer {
    font-size: 1rem;
    color: #fff;
    font-weight: bold;
    text-align: center;
    background-color: #ff1500;
    padding: 8px;
    border-radius: 8px;
    margin: 10px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4px;
  }
  
  .countdown-timer:hover {
    background-color: #ff4a3d;
  }
  
  .countdown-timer .timer-text {
    margin-bottom: 8px;
  }
  
  .countdown-timer .timer-content {
    display: flex;
    align-items: center;
    gap: 8px; /* Añadir espacio entre los elementos */
  }
  
  .countdown-timer .timer-content span:not(.emoji) {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4px;
    background: rgba(0, 0, 0, 0.2);
    border-radius: 4px;
    width: 40px;
    height: 55px;
    transition: background 0.3s ease, transform 0.3s ease;
  }
  
  .countdown-timer .timer-content span:not(.emoji) span {
    display: block;
    font-size: 1.2rem;
    line-height: 1;
  }
  
  .countdown-timer .timer-content span:not(.emoji) label {
    display: block;
    font-size: 0.5rem;
    margin-top: 1px;
  }
  
  .emoji {
    font-size: 1.5rem;
    animation: blink 1s infinite;
    background: none; /* Sin fondo para los emojis */
    border: none; /* Sin bordes para los emojis */
  }
  
  @keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
  
  /* Keyframes para la animación */
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: scale(0.9);
    }
    100% {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .time-segment.animate {
    animation: fadeIn 0.5s ease-in-out;
  }
  