.alert {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: 10px 15px; /* Reducir el padding */
    background-color: #2a9d8f; /* Cambiamos a un tono verde azulado más suave */
    color: #ffffff;
    border-radius: 10px; /* Bordes más redondeados */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2); /* Sombra más prominente para mayor profundidad */
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 1050; 
    transition: all 0.4s ease-in-out; 
    transform: scale(0.9);
    opacity: 0;
    visibility: hidden;
  }
  
  .alert.visible {
    transform: scale(1);
    opacity: 1;
    visibility: visible;
  }
  
  .alert img {
    width: 40px; /* Reducir el tamaño de la imagen */
    height: 40px; /* Reducir el tamaño de la imagen */
    object-fit: cover;
    margin-right: 10px; /* Reducir el margen */
    border-radius: 20px; /* Mantener la proporción de redondeado */
  }
  
  .close-btn {
    background: none;
    border: none;
    color: #ffffff;
    font-size: 20px; /* Reducir el tamaño de la fuente */
    cursor: pointer;
    opacity: 0.85;
    padding: 0 5px; /* Reducir el padding */
    transition: opacity 0.2s ease-in-out;
  }
  
  .close-btn:hover {
    opacity: 1;
  }
  